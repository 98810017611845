<template>
	<div class="" style="position: relative; height: 100vh; color: #ffffff;">
		<div class="view" style="height: calc(100vh - 64px); width: 100vw">
			<router-view />
		</div>

    <div style="position: absolute; bottom: 0;"><Nav></Nav></div>
	</div>
</template>

<script>
import Nav from '~/frame/nav'
export default {
  mounted () {},
  methods: {},
  components: { Nav }
}
</script>

<style>
/* .layout { display: flex; width: 100vw; }
.view { width: 100%; } */

</style>